<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20">
      <!-- 进货仓库 -->
      <FormItem class="">
        <span>单据编号：</span>
        <Input v-model="queryFrom.receipts_code" placeholder="请输入" class="iviewIptWidth250" />
      </FormItem>
      <FormItem class="marginLeft60">
        <span>单据时间：</span>
        <DatePicker type="date" @on-change="changeTime($event, 1)" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" @on-change="changeTime($event, 2)" :options="options" v-model="queryFrom.receipts_time_end" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <FormItem class="po-create-number marginLeft60">
        <span class="pageBtn finger btnSure" @click="query()">查询</span>
      </FormItem>
    </Form>
    <div class="clearfix tabDiv" ref="tabDiv">
      <Spin fix v-if="Loading"></Spin>
      <Table :columns="listColumns" :loading="loading" :data="listData" border class="table" :pages="queryFrom" :total="total" @change-page="changePage" totalText="条记录"> </Table>
      <div style="margintop: 20px;" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
        <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
        <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0 && !Loading">
        <img style="margin-top: 100px;" src="@/assets/images/tableNoData.png" />
      </div>
    </div>
  </div>
</template>

<script>
// import Table from '@/components/table'
export default {
  name: 'factorySupplyList',
  components: {
    // Table,
  },
  data() {
    return {
      options: {},
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 60,
          key: 'index',
        },
        {
          title: '单据编号',
          key: 'receipts_code',
          align: 'center',
          width: 200,
        },
        {
          title: '单据类型',
          key: 'receipts_type_desc',
          align: 'center',
          width: 200,
        },
        {
          title: '单据时间',
          key: 'receipts_time',
          align: 'center',
          width: 200,
        },
        {
          title: '异常详情',
          key: 'abnormal_detail',
          align: 'center',
          minwidth: 300,
        },
      ],
      // 查询参数
      queryFrom: {
        receipts_time_start: '',
        receipts_time_end: '',
        receipts_code: '',
        page: 1,
        rows: 10,
      },
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      Loading: true,
      tableHeight: '0',
    }
  },
  methods: {
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.receipts_time_start = e
        that.queryFrom.receipts_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.receipts_time_start)
          },
        }
      } else {
        this.queryFrom.receipts_time_end = e
      }
    },
    // 点击查询
    query() {
      this.queryFrom.page = 1
      this.getfactorySupplyList()
    },
    // 页码改变
    changePage(e) {
      this.queryFrom.page = e
      this.getfactorySupplyList()
    },
    // 获取表格
    getfactorySupplyList() {
      this.Loading = true
      let data = {
        page: this.queryFrom.page, // 页
        rows: this.queryFrom.rows, // 行
        receipts_code: this.queryFrom.receipts_code,
        receipts_time_start: this.queryFrom.receipts_time_start,
        receipts_time_end: this.queryFrom.receipts_time_end,
      }
      this.$http.get(this.$api.abnormalReceipts, data, false).then(res => {
        this.Loading = false
        this.total = res.total ? res.total : 0
        // 存储表格数据
        this.listData = res.data ? res.data : []
        if (this.listData.length > 0) {
          this.listData.forEach(item => {
            item.receipts_time = item.receipts_time ? this.$moment.unix(item.receipts_time).format('YYYY-MM-DD HH:mm:ss') : ''
          })
        }
        this.$nextTick(() => {
          this.tableHeight = this.$refs.tabDiv.offsetHeight - 50 + ''
          this.$forceUpdate()
        })
      })
    },
  },
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
}
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}
.marginTop20 {
  margin-top: 20px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
</style>
